@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background-color: #f4faf8;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
    font-weight: revert;
  }

  /*  montserrat - regular  */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/montserrat/montserrat-v25-latin-regular.woff");
  }

  /*  montserrat - 500  */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: url("./assets/fonts/montserrat/montserrat-v25-latin-500.woff");
  }

  /*  montserrat - 700  */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url("./assets/fonts/montserrat/montserrat-v25-latin-700.woff");
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}
